<template>
  <div>
    <input type="checkbox" id="hide-checkbox" v-model="isChecked" @change="toggleBodyClass">
    <label for="hide-checkbox" class="toggle">
      <span class="toggle-button">
        <span class="crater crater-1"></span>
        <span class="crater crater-2"></span>
        <span class="crater crater-3"></span>
        <span class="crater crater-4"></span>
        <span class="crater crater-5"></span>
      </span>
      <span class="star star-1"></span>
      <span class="star star-2"></span>
      <span class="star star-3"></span>
      <span class="star star-5"></span>
      <span class="star star-6"></span>
    </label>
  </div>
</template>


<script>
export default {
  data() {
    return {
      isChecked: false,
    };
  },
  methods: {
    toggleBodyClass() {
      if (this.isChecked) {
        document.body.classList.add('lightTheme');
      } else {
        document.body.classList.remove('lightTheme');
      }
    },
  },
};
</script>