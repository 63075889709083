<template>
  <nav class="navbar" :class="{ 'scrolled': isScrolled }">
    <div class="margin-width flex-div">
      <div class="navbar-logo">
        <router-link to="/">UI DEVELOPER</router-link>
      </div>
      <div>
        <div class="nav-outer" :class="{ active: isClassActive }">
          <ul class="navbar-menu" :class="{ active2: isClassActive2 }">
            <li><a href="#home" @click="scrollToSection('#home')">Home</a></li>
            <li><a href="#about" @click="scrollToSection('#about')">About Us</a></li>
            <li><a href="#skills" @click="scrollToSection('#skills')">Skills</a></li>
            <li><a href="#portfolio" @click="scrollToSection('#portfolio')">Portfolio</a></li>
            <a href="#contact" class="themeColor getintouchbut" @click="scrollToSection('#contact')">Get In Touch</a>
          </ul>
          <div class="menuToggle" @click="toggleMenu">
            <span class="material-symbols-outlined whiteText">
              menu
            </span>
          </div>

          <div class="DayNight">
            <day-night-toggle />
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>
    
<script>
import DayNightToggle from '@/components/DayNightToggle.vue';
export default {
  name: 'NavbarSection',
  components: {
    DayNightToggle
  },

  data() {
    return {
      isScrolled: false,
      isMorning: false,
      isClassActive: false,
      isClassActive2: false,
    };
  },

  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  methods: {
    scrollToSection(sectionId) {
      this.isClassActive2 = true;
      const targetSection = document.querySelector(sectionId);

      if (targetSection) {
        targetSection.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    },

    toggleMenu() {
      this.isClassActive2 = false;
      this.isClassActive = !this.isClassActive;

    },
    handleScroll() {
      const scrollPosition = window.scrollY;
      const threshold = 100;
      this.isScrolled = scrollPosition > threshold;
    },

  },
}

</script>
    
    
    
    
    