<template>
    <div>
      <a :href="link" target="_blank">
        <div class="content-overlay"></div>
        <img class="content-image" :src="imageSrc" />
        <div class="content-details fadeIn-bottom">
          <h3 class="content-title">{{ title }}</h3>
          <h4>{{ description }}</h4>
          <p class="content-text">{{ content }}</p>
          <div class="linkweb whiteText">{{ url }}</div>
          <div class="linkUrl whiteText">
            <a :href="copyUrl" class="copyURL" title="Copy URL">
              <span class="material-symbols-outlined">content_copy</span>
            </a>
            <a :href="externalUrl" target="_blank" class="copyURL" title="Go to URL">
              <span class="material-symbols-outlined">captive_portal</span>
            </a>
          </div>
        </div>
    </a>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      title: String,
      description: String,
      content: String,
      url: String,
      imageSrc: String,
      link: String,
      copyUrl: String,
      externalUrl: String,
    },
  };
  </script>
  