<template>
  <div class="pagination">
    <button @click="previousPage" :disabled="currentPage === 1"><span class="material-symbols-outlined">
        chevron_left
      </span></button>
    <span>{{ currentPage }} / {{ totalPages }}</span>
    <button @click="nextPage" :disabled="currentPage === totalPages"><span class="material-symbols-outlined">
        chevron_right
      </span></button>
  </div>
</template>
  
<script>
export default {
  props: {
    currentPage: Number,
    totalPages: Number,
  },
  methods: {
    previousPage() {
      if (this.currentPage > 1) {
        this.$emit("page-change", this.currentPage - 1);
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.$emit("page-change", this.currentPage + 1);
      }
    },
  },
};
</script>
  
<style></style>
  