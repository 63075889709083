<template>
  
  <NavbarSection />
  <div class="bannerSection" id="home">
    <div class="margin-width overlayer">
      <div class="bannerContent">

        <div class="hello">Hello, I am</div>
        <div class="down-arrow"></div>
        <div class="name">Pawan</div>
        <div class="intro">I’ve spent most of these years working across different areas of design
           like front-end development, landing pages, email design, app UI/UX and also work in <b style="color:#ff1d83; font-style: italic;">freelancing.</b> </div>
        <div class="bannerButton">
          <a href="https://wa.me/9319821293" target="_blank" class="themeColor bannerChildButton transparentborder">Hire Me</a>
          <a href="#portfolio" class="bannerChildButton borderButton ml-20" @click="scrollToSection('#portfolio')">My Work</a>
        </div>
      </div>
    </div>
  </div>

  <div class="aboutSection" id="about">
    <div class="margin-width">
      <span class="aboutMe">About me</span>
      <div class="aboutHeading">
        I am an enthusiastic front-end developer based in Delhi-NCR, with a rich experience of over 6 years in Graphic & Web
        design.
      </div>
      <div class="flex-div">
        <div class="aboutImg">
          <img src="./assets/images/pawanAboutImg.jpg" alt="Pawan About Us Images">
        </div>
        <div class="aboutImgContent">
          <h5 class="whiteText">Why Hire Me For Your Next Service</h5>
          <p class="whiteText">With years of experience in graphic and web design I have mastered the skills
             of understanding client requirements according to the latest trends. I have worked with businesses from
              different niches so you can rely on me for yours.<br />
              I’ve spent most of these years working across different areas of design like front-end development,
               landing pages, email design, app UI/UX.
                Having worked on various projects that are already live, I can help you with the best possible suggestions 
                and ideas that we can proceed with. With me, you aren’t forced to accept anything. I give you a variety of
                 options we can work on together.
            </p>
          <div class="bannerButton">
            <a href="https://www.linkedin.com/in/pawan-kandari-961a5895/" target="_blank" class="themeColor bannerChildButton transparentborder">LinkedIn</a>
            <a href="https://drive.google.com/uc?export=view&id=1DBH1D6_EQhLlz39HW51AoOgiC5i-Ywvf" target="_blank" class="bannerChildButton borderButton ml-20 pd-10">Download My CV</a>
          </div>
        </div>

      </div>

    </div>
  </div>


  <div class="mySkills" id="skills">
    <div class="margin-width">
      <h5 class="whiteText textCenter">My Skiils</h5>
      <p class="whiteText textCenter">I believe in the fact that learning never stops and I am interested in learning new
         technologies<br /> and programming languages that will help me design and develop a better <br />output in less time.</p>

      <div class="skillsBoxSection">

        <div class="skillBox">
          <div class="skillBoxUnder">
            <div class="skillBoxUnderChild">
              <div class="skillIcon"><span class="material-symbols-outlined font-30">
                  html
                </span></div>
              <div class="skillcontent whiteText">HTML,CSS, Responsive websites</div>
            </div>
            <div class="arrowMain">
              <div class="skillarrow"><span class="material-symbols-outlined">
                  east
                </span></div>
            </div>
          </div>
        </div>

        <div class="skillBox">
          <div class="skillBoxUnder">
            <div class="skillBoxUnderChild">
              <div class="skillIcon"><span class="material-symbols-outlined font-30">
                  code
                </span></div>
              <div class="skillcontent whiteText">JavaScript,Jquery</div>
            </div>
            <div class="arrowMain">
              <div class="skillarrow"><span class="material-symbols-outlined">
                  east
                </span></div>
            </div>
          </div>
        </div>

        <div class="skillBox">
          <div class="skillBoxUnder">
            <div class="skillBoxUnderChild">
              <div class="skillIcon">
                <img src="./assets/images/VueIcon.png" alt="Pawan vue Icon">
              </div>
              <div class="skillcontent whiteText">Vue js</div>
            </div>
            <div class="arrowMain">
              <div class="skillarrow"><span class="material-symbols-outlined">
                  east
                </span></div>
            </div>
          </div>
        </div>

        <div class="skillBox">
          <div class="skillBoxUnder">
            <div class="skillBoxUnderChild">
              <div class="skillIcon">
                <img src="./assets/images/photoshopIcon.png" alt="Photoshop Icon">
              </div>
              <div class="skillcontent whiteText">Adobe Photoshop</div>
            </div>
            <div class="arrowMain">
              <div class="skillarrow"><span class="material-symbols-outlined">
                  east
                </span></div>
            </div>
          </div>
        </div>

        <div class="skillBox">
          <div class="skillBoxUnder">
            <div class="skillBoxUnderChild">
              <div class="skillIcon"><img src="./assets/images/XDIcon.png" alt="XD Icon"></div>
              <div class="skillcontent whiteText">Adobe XD, Figma</div>
            </div>
            <div class="arrowMain">
              <div class="skillarrow"><span class="material-symbols-outlined">
                  east
                </span></div>
            </div>
          </div>
        </div>

        <div class="skillBox">
          <div class="skillBoxUnder">
            <div class="skillBoxUnderChild">
              <div class="skillIcon"><span class="material-symbols-outlined font-30">
                  <span class="material-symbols-outlined">
                    more_horiz
                  </span>
                </span></div>
              <div class="skillcontent whiteText">More Skills</div>
            </div>
            <div class="arrowMain">
              <div class="skillarrow"><span class="material-symbols-outlined">
                  east
                </span></div>
            </div>
          </div>
        </div>

      </div>

    </div>
  </div>

  <div class="myExp">
    <div class="margin-width">
      <div class="flex-div-end">
        <div class="expImg">
          <img src="./assets/images/expImg.jpg" alt="Experince Image">
        </div>

        <div class="expContent">
          <h5 class="whiteText">Experience</h5>
          <div class="scrollsec">
            <div class="flex-div">
              <div class="years">
                <div class="childYear whiteText">May 2023 Currently Working</div>
                <div class="lineBottom"></div>
                <div class="companyName whiteText">HRBotics</div>
              </div>

              <div class="yearContent whiteText">
                <h4>UI Developer</h4>
                <p>I have responsibly led a team of 12 designers to help, supervise and approve their designs to make sure
                  it
                  matches</p>
              </div>
            </div>

            <div class="flex-div">
              <div class="years">
                <div class="childYear whiteText">July 2022 - April 2023</div>
                <div class="lineBottom"></div>
                <div class="companyName whiteText">Sriggle Tech Pvt. Ltd.</div>
              </div>

              <div class="yearContent whiteText">
                <h4>UI Developer</h4>
                <p>I have responsibly led a team of 12 designers to help, supervise and approve their designs to make sure
                  it
                  matches</p>
              </div>
            </div>

            <div class="flex-div">
              <div class="years">
                <div class="childYear whiteText">Oct 2020 - June 2022</div>
                <div class="lineBottom"></div>
                <div class="companyName whiteText">Chetu India Pvt. Ltd.</div>
              </div>

              <div class="yearContent whiteText">
                <h4>UI Developer</h4>
                <p>I have responsibly led a team of 12 designers to help, supervise and approve their designs to make sure
                  it
                  matches</p>
              </div>
            </div>

            <div class="flex-div">
              <div class="years">
                <div class="childYear whiteText">April 2018 - Oct 2020</div>
                <div class="lineBottom"></div>
                <div class="companyName whiteText">Skylark Infotech Pvt. Ltd.</div>
              </div>

              <div class="yearContent whiteText">
                <h4>UI Developer</h4>
                <p>I have responsibly led a team of 12 designers to help, supervise and</p>
              </div>
            </div>

            <div class="flex-div">
              <div class="years">
                <div class="childYear whiteText">Nov 2016 - Feb 2018</div>
                <div class="lineBottom"></div>
                <div class="companyName whiteText">AO Software Solution Pvt. Ltd.</div>
              </div>

              <div class="yearContent whiteText">
                <h4>UI Developer</h4>
                <p>I have responsibly led a team of 12 designers to help, supervise and approve</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="portfolio" id="portfolio">
    <div class="margin-width">
      <h5 class="whiteText textCenter">My Latest Work</h5>
      

      <div class="portfolioFlex">
        <ul class="w-100">
      <li v-for="item in paginatedData" :key="item.id" class="portfolioCard">
        <portfolio-card :link="item.link" :title="item.title" :description="item.description" :content="item.content" :url="item.url" :imageSrc="item.imageSrc" :copyUrl="item.copyUrl" :externalUrl="item.externalUrl" />
      </li>
    </ul>
   

      </div>
            <!-- Include the pagination component -->
    <PageNavigator
      :current-page="currentPage"
      :total-pages="totalPages"
      @page-change="onPageChange"
    />
    </div>
  </div>

<div class="hireMe">
  <div class="hireMeUnder textCenter whiteText">
<p>Have any Project in Mind?</p>
<h3>Interested In Working Together With Me?</h3>
<a href="https://wa.me/9319821293" target="_blank" class="themeColor">Let’s Talk</a>
  </div>
</div>



<div class="footerSection" id="contact">
<div class="margin-width">
  <h5 class="whiteText textCenter">Contact Me</h5>
      <p class="whiteText textCenter">Whether you wish to discuss new ideas or have a project for me, <br />simply feel free contact.
      </p>
<div class="flex-div">
<div class="footerCard">
<div class="fotterIcon"><span class="material-symbols-outlined whiteText">
phone_in_talk
</span></div>
<div class="footerContent">
  <span>Call Me</span>
  <p class="whiteText">+91 9319821293</p>
</div>
  </div>

  <div class="footerCard">
<div class="fotterIcon"><span class="material-symbols-outlined whiteText">
mail
</span></div>
<div class="footerContent">
  <span>Email</span>
  <p><a href="mailto:pawankandari97@gmail.com" class="whiteText">pawankandari97@gmail.com</a></p>
</div>
  </div>

  <div class="footerCard">
<div class="fotterIcon"><span class="material-symbols-outlined whiteText">
location_on
</span></div>
<div class="footerContent">
  <span>Location</span>
  <p class="whiteText">Delhi, India</p>
</div>
  </div>

  </div>
</div>
</div>



 <div class="footerSocialIcons_outer"> 
  <div class="footerSocialIcons">
  <a href="https://www.facebook.com/pawankandari97" target="_blank"><i class="fa-brands fa fa-facebook-f" target="_blank"></i></a>
  <a href="https://www.instagram.com/pawansk31/" target="_blank"><i class="fa-brands fa fa-instagram"></i></a>
  <a href="https://www.linkedin.com/in/pawan-kandari-961a5895/"><i class="fa-brands fa fa-linkedin" target="_blank"></i></a>
  <a href="https://github.com/pawansk"><i class="fa-brands fa fa-github" target="_blank"></i></a>
  </div>
</div> 

<div class="whatsappIcon"><a href="https://wa.me/9319821293" target="_blank" class="whiteText"><i class="fa-brands fa fa-whatsapp whiteText"></i></a></div>


</template>

<script>
import NavbarSection from './components/Navbar.vue';
import PortfolioCard from './components/PortfolioCard.vue';
import PageNavigator from './components/PageNavigator.vue';


export default {
  name: 'App',
  components: {
    NavbarSection,
    PortfolioCard,
    PageNavigator
  },
  data() {
    return {
      currentPage: 1,
      itemsPerPage:3,
      data: [
      {
        id:1,
        link:"https://silvertray.dk",
        title: "Silvertray",
        description: "Adobe XD, HTML, CSS, JavaScript",
        content: "This website is based on tour and travels. You can book hotels and rooms.",
        url: "https://silvertray.dk",
        imageSrc: "/images/siverTray.jpg",
        alt: "Pawan project images",
        copyUrl: "",
        externalUrl: "https://silvertray.dk",
      },
         {
        id:2,
        link:"https://onequext.com/",
        imageSrc:"/images/quext.jpg",
        alt: "Pawan project images",
        title:"Quext",
        description:"HTML, Tailwind Css, Vue js",
        content:"This website is based on book Hotels, apartment, Rooms, Lease Hotels, Rooms",
        url:"https://onequext.com/",
        copyUrl:"",
        externalUrl:"https://onequext.com/", 
     },
{
        id:3,
        link:"https://www.enroma.com/",
        imageSrc:"/images/Enroma.jpg",
        alt: "Pawan project images",
        title:"Enroma",
        description:"HTML, CSS, JavaScript",
        content:"This website is based on tour and travels. You can book hotels and rooms.",
        url:"https://www.enroma.com/",
        copyUrl:"",
        externalUrl:"https://www.enroma.com/",
},
{
        id:4,
        link:"https://www.himalayanmartonline.com/",
        imageSrc:"/images/himalayan.jpg",
        alt: "Pawan project images",
        title:"Himalayan Mart Online",
        description:"HTML, CSS, JavaScript, Photoshop, Adobe XD,",
        content:"Himalayan Mart.com is an online market for Nepalese handicrafts bringing you a pleasant online shopping experience with; carefully selected Nepalese handicrafts.",
        url:"https://www.himalayanmartonline.com/",
        copyUrl:"",
        externalUrl:"https://www.himalayanmartonline.com/",
},
{
        id:5,
        link:"https://dharamkranti.com/",
        imageSrc:"/images/dharmkrant.jpg",
        alt: "Pawan project images",
        title:"Dharm Karanti",
        description:"HTML, CSS, JavaScript, Photoshop, XD",
        content:"This website is based on Local News Portal.",
        url:"https://dharamkranti.com/",
        copyUrl:"",
        externalUrl:"https://dharamkranti.com/",
},
{
        id:6,
        link:"https://www.toptenfares.com/",
        imageSrc:"/images/toptenfares.jpg",
        alt: "Pawan project images",
        title:"Top Ten Fares",
        description:"HTML, CSS, JavaScript",
        content:"This website is based on tour and travels. You can book hotels and rooms.",
        url:"https://www.toptenfares.com/",
        copyUrl:"",
        externalUrl:"https://www.toptenfares.com/",
},
{
        id:7,
        link:"https://myassignmenthelpau.com/",
        imageSrc:"/images/myassignment.jpg",
        alt: "Pawan project images",
        title:"My Assignment Help AU",
        description:"HTML, CSS, JavaScript",
        content:"This portal help is the personalized assistance they offer. In essay projects, students can learn from experts who provide guidance and support in the specific subject area. ",
        url:"https://myassignmenthelpau.com/",
        copyUrl:"",
        externalUrl:"https://www.myassignmenthelpau.com/",
},
{
        id:8,
        link:"https://www.dhanux.com/",
        imageSrc:"/images/dhanux.jpg",
        alt: "Pawan project images",
        title:"Dhanux",
        description:"HTML, CSS, JavaScript",
        content:"This website based on the world's trusted and biggest exchange. With almost several different crypto coins in option, you can trade ",
        url:"https://www.dhanux.com/",
        copyUrl:"",
        externalUrl:"https://www.dhanux.com/",
},
{
        id:9,
        link:"https://www.sehat365.com/",
        imageSrc:"/images/sehat.jpg",
        alt: "Pawan project images",
        title:"Sehat365",
        description:"HTML, CSS, JavaScript, Photoshop",
        content:"This website based on health realeaetd news Portal.",
        url:"https://www.sehat365.com/",
        copyUrl:"",
        externalUrl:"https://www.sehat365.com/",
},
{
        id:10,
        link:"https://elitingbrands.com/",
        imageSrc:"/images/ElitingBrand.jpg",
        alt: "Pawan project images",
        title:"Eliting Brand",
        description:"Adobe XD, HTML, CSS, JavaScript, Ecwid",
        content:"",
        url:"https://elitingbrands.com/",
        copyUrl:"",
        externalUrl:"https://elitingbrands.com/",
},
{
        id:11,
        link:"https://hrbotics.com/",
        imageSrc:"/images/HRbotics.jpg",
        alt: "Pawan project images",
        title:"HR Botics",
        description:"Adobe XD, HTML, CSS, JavaScript, Ecwid",
        content:"",
        url:"https://hrbotics.com/",
        copyUrl:"",
        externalUrl:"https://hrbotics.com/",
},
{
        id:12,
        link:"https://eduroids.com/",
        imageSrc:"/images/Eduroids.jpg",
        alt: "Pawan project images",
        title:"Edu Roids",
        description:"Adobe XD, HTML, CSS, JavaScript, Ecwid",
        content:"",
        url:"https://eduroids.com/",
        copyUrl:"",
        externalUrl:"https://eduroids.com/",
},
    ],
    }
  },
  computed: {
    totalItems() {
      return this.data.length;
    },
    totalPages() {
      return Math.ceil(this.totalItems / this.itemsPerPage);
    },
    paginatedData() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.data.slice(startIndex, endIndex);
    },
  },
  methods: {
    onPageChange(page) {
      this.currentPage = page;
    },
    scrollToSection(sectionId) {
      const targetSection = document.querySelector(sectionId);

      if (targetSection) {
        targetSection.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    }
  },


}

</script>
